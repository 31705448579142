const linkResolverSitemap = require('./linkResolverSitemap.json');

const linkResolver = ({ node, key, value } = {}) => doc => {
    function returnFullPageSlug(uid) {
    	const object = linkResolverSitemap.pages.find(obj => obj.uid === uid);
		return (object) ? object.slug : `/${doc.uid}`;
	}
	
    switch(doc.type) {
        case "home": return '/';
        case "blog_article": return returnFullPageSlug(doc.uid);
        case "blog_listing": return `/${doc.uid}`;
		case "content": return returnFullPageSlug(doc.uid);
        default: return "/";
    }
 }

module.exports = linkResolver